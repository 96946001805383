var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"transparent",attrs:{"loading":_vm.loading,"outlined":"","tile":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"width":"100%"},on:{"click":_vm.clickRouting}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.searchOptionIcons[_vm.searchOption]))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.searchOptionText[_vm.searchOption]))])])],1),_c('v-col',{attrs:{"cols":"12","sm":_vm.showRenewJobs ? 8 : 10}},[_c('v-input',{attrs:{"append-icon":"mdi-close","prepend-icon":"mdi-magnify","loading":_vm.searchLoading,"hide-details":"auto"},on:{"click:append":function($event){_vm.jobSearch = ''},"click:prepend":_vm.searchJob}},[_c('v-text-field',{attrs:{"dense":"","label":"Job Nummer","number":"","hide-details":"auto"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchJob.apply(null, arguments)}},model:{value:(_vm.jobSearch),callback:function ($$v) {_vm.jobSearch=$$v},expression:"jobSearch"}})],1)],1),(_vm.showRenewJobs)?_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-btn',{attrs:{"width":"100%"},on:{"click":_vm.searchJobAPI}},[_vm._v(" Renew Jobs ")])],1):_vm._e()],1)],1)],1)],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_c('v-col',[_c('v-row',[_vm._l((_vm.showTheseJobs),function(job,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-tooltip',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"grey lighten-5 clickable",on:{"click":function($event){return _vm.chooseThisJob(job)}}},'v-card',attrs,false),on),[_c('v-card-title',{staticClass:"grey lighten-2"},[_vm._v(" "+_vm._s(job.jobNumber)+" "+_vm._s(job.jobItemNumber ? "-" + job.jobItemNumber : "")+" "),_c('v-spacer'),_c('v-icon',[_vm._v(_vm._s(!job.routing ? "mdi-checkbox-blank-circle-outline" : job.routings.length == _vm.getRoutingTypesLength ? "mdi-checkbox-marked-circle-outline" : "mdi-radiobox-marked"))])],1),_c('v-card-text',[_c('i',[_vm._v(" "+_vm._s(job.requiredEndDt)+" ")]),_c('br'),_vm._v(" "+_vm._s(job.description)+" ")])],1)]}}],null,true)},[_c('span',_vm._l((_vm.board.routingTypes),function(rtype){return _c('div',{key:rtype.id},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(_vm.routingTypeIsUsed(rtype.shortName, job) ? "mdi-checkbox-marked-circle-outline" : "mdi-checkbox-blank-circle-outline")+" ")]),_vm._v(" "+_vm._s(rtype.name)+" ("+_vm._s(rtype.shortName)+") ")],1)}),0)])]}}],null,true)})],1)}),(
            _vm.showTheseJobs &&
            _vm.showTheseJobs.length != 0 &&
            !(_vm.showTheseJobs.length < _vm.showThisMuchJobs)
          )?_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('v-card',{staticClass:"grey lighten-5 clickable",attrs:{"height":"100%"},on:{"click":_vm.showMoreJobs}},[_c('v-card-title',{staticClass:"grey lighten-2"},[_vm._v(" Show more jobs ")]),_c('v-card-text',[_vm._v(" showing: "+_vm._s(_vm.showThisMuchJobs)+" "),_c('br')])],1)],1):_vm._e(),(_vm.showTheseJobs && _vm.showTheseJobs.length == 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"grey lighten-5"},[_c('v-card-title',{staticClass:"grey lighten-2"},[_c('i',[_vm._v(" Geen jobs gevonden, probeer opnieuw. ")])])],1)],1):_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }