<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card :loading="loading" outlined tile class="transparent">
          <!-- <v-card-title class="transparent"> Kies een job </v-card-title> -->
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      width="100%"
                      @click="clickRouting"
                    >
                      <v-icon>{{ searchOptionIcons[searchOption] }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ searchOptionText[searchOption] }}</span>
                </v-tooltip>
                <!-- <v-checkbox
                  v-model="fullRoutingSearch"
                  label="routing"
                  on-icon="mdi-checkbox-marked-circle-outline"
                  off-icon="mdi-checkbox-blank-circle-outline"
                  indeterminate-icon="mdi-radiobox-marked"
                  @click="searchJob"
                >
                </v-checkbox> -->
              </v-col>
              <v-col cols="12" :sm="showRenewJobs ? 8 : 10">
                <v-input
                  append-icon="mdi-close"
                  prepend-icon="mdi-magnify"
                  @click:append="jobSearch = ''"
                  @click:prepend="searchJob"
                  :loading="searchLoading"
                  hide-details="auto"
                >
                  <v-text-field
                    dense
                    label="Job Nummer"
                    number
                    v-model="jobSearch"
                    @keydown.enter="searchJob"
                    hide-details="auto"
                  >
                  </v-text-field>
                </v-input>
              </v-col>
              <v-col cols="12" sm="2" v-if="showRenewJobs">
                <v-btn width="100%" @click="searchJobAPI"> Renew Jobs </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-show="!loading">
      <v-col>
        <v-row>
          <!-- Jobs -->
          <v-col
            v-for="(job, index) in showTheseJobs"
            :key="index"
            cols="12"
            sm="6"
            lg="4"
            xl="3"
          >
            <v-hover v-slot="{ hover }">
              <v-tooltip v-show="hover" right>
                <template v-slot:activator="{ on, attrs }">
                  <v-card
                    v-bind="attrs"
                    v-on="on"
                    class="grey lighten-5 clickable"
                    @click="chooseThisJob(job)"
                  >
                    <v-card-title class="grey lighten-2">
                      {{ job.jobNumber }}
                      {{ job.jobItemNumber ? "-" + job.jobItemNumber : "" }}
                      <v-spacer />
                      <v-icon>{{
                        !job.routing
                          ? "mdi-checkbox-blank-circle-outline"
                          : job.routings.length == getRoutingTypesLength
                          ? "mdi-checkbox-marked-circle-outline"
                          : "mdi-radiobox-marked"
                      }}</v-icon>
                    </v-card-title>
                    <v-card-text>
                      <i>
                        {{ job.requiredEndDt }}
                      </i>
                      <br />
                      {{ job.description }}
                    </v-card-text>
                  </v-card>
                </template>
                <span>
                  <div v-for="rtype in board.routingTypes" :key="rtype.id">
                    <v-icon dark>
                      {{
                        routingTypeIsUsed(rtype.shortName, job)
                          ? "mdi-checkbox-marked-circle-outline"
                          : "mdi-checkbox-blank-circle-outline"
                      }}
                    </v-icon>
                    {{ rtype.name }} ({{ rtype.shortName }})
                  </div>
                </span>
              </v-tooltip>
            </v-hover>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            lg="4"
            xl="3"
            v-if="
              showTheseJobs &&
              showTheseJobs.length != 0 &&
              !(showTheseJobs.length < showThisMuchJobs)
            "
          >
            <v-card
              class="grey lighten-5 clickable"
              @click="showMoreJobs"
              height="100%"
            >
              <v-card-title class="grey lighten-2">
                Show more jobs
              </v-card-title>
              <v-card-text>
                showing: {{ showThisMuchJobs }}
                <br />
              </v-card-text>
            </v-card>
          </v-col>

          <!-- If no jobs found -->
          <v-col v-if="showTheseJobs && showTheseJobs.length == 0" cols="12">
            <v-card class="grey lighten-5">
              <v-card-title class="grey lighten-2">
                <i> Geen jobs gevonden, probeer opnieuw. </i>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import services from "../../services/services.js";
import { mapState } from "vuex";

export default {
  created() {
    if (this.$store.getters.getJobs == null) {
      this.searchJobAPI();
    } else {
      this.initialLoading = false;
    }
  },
  computed: {
    ...mapState({
      board: (state) => state.board,
    }),
    getRoutingTypesLength() {
      return this.$store.getters.getRoutingTypesLength;
    },
    jobs() {
      return this.$store.getters.getJobs;
    },
    showTheseJobs() {
      if (this.jobs) {
        //console.log("filtering jobs: " +this.filter.jobSearch + "/" + this.filter.searchOption);
        // filter jobs here on filter.jobSearch && filter.searchOption
        let filteredJobs = this.jobs;
        if (this.filter.jobSearch && this.filter.jobSearch != "") {
          filteredJobs = filteredJobs.filter((job) =>
            job.jobNumber.includes(this.filter.jobSearch)
          );
        }
        if (this.filter.searchOption != null && this.filter.searchOption < 4) {
          // if (this.filter.searchOption == 0) {
          filteredJobs = filteredJobs.filter(this.filterJobSearchOption);
          // }
        }
        // console.log(filteredJobs);
        return filteredJobs.slice(0, this.showThisMuchJobs);
      }
      return [];
    },
    loading() {
      // console.log(this.loadingCount);
      // console.log(this.initialLoading);
      return this.loadingCount != 0 || this.initialLoading;
    },
  },
  data() {
    return {
      filter: { jobSearch: "", searchOption: window.__env.searchOption },
      showThisMuchJobs: 60,
      fullRoutingSearch: false,
      jobSearch: "",
      searchLoading: false,
      // jobs: [],
      //loading: true,
      loadingCount: 0,
      initialLoading: true,
      showRenewJobs: window.__env.showRenewJobs,
      searchOption: window.__env.searchOption,
      searchOptionText: [
        "only empty routings",
        "not full routings",
        "not empty not full routings",
        "only full routings",
        "all routings",
      ],
      searchOptionIcons: [
        "mdi-checkbox-blank-circle-outline",
        "mdi-circle-double",
        "mdi-radiobox-marked",
        "mdi-checkbox-marked-circle-outline",
        "mdi-all-inclusive",
      ],
    };
  },
  methods: {
    getCheckBox(job) {
      ////// general icon for hovertip (for all routings in one)
      if (!job.routing) {
        return "mdi-checkbox-blank-circle-outline";
      } else if (
        job.routing.length == this.$store.getters.getRoutingTypesLength
      ) {
        return "mdi-checkbox-marked-circle-outline";
      }
      return "mdi-radiobox-marked";
    },
    chooseThisJob(job) {
      services
        .getJob(job.jobNumber, job.jobItemNumber)
        .then((response) => {
          this.$store.commit("SET_JOB", {
            job: response.data,
          });
          this.$router.push({ name: "job/routingTypes" });
        })
        .catch((error) => {
          this.$store.commit("SAVE_ERROR", { error });
          console.log("There are errors: ", error.response);
        });
    },
    clickRouting() {
      this.searchOption == 4
        ? (this.searchOption = 0)
        : (this.searchOption += 1);
      this.searchJob();
    },
    searchJob() {
      // console.log("searching jobs");
      this.loadingCount++;
      this.searchLoading = true;

      this.filter.jobSearch = this.jobSearch;
      this.filter.searchOption = this.searchOption;

      this.loadingCount--;
      this.searchLoading = false;
    },
    searchJobAPI() {
      console.log("getting jobs");
      this.loadingCount++;
      this.searchLoading = true;
      // this.loading = true;
      services
        .getJobsBySearch("", 4) //this.jobSearch,this.searchOption) //have to get all jobs, then filter by search
        .then((response) => {
          //this.jobs = response.data;
          let jobs = response.data;
          // console.log(jobs);
          this.$store.commit("SAVE_JOBS", {
            jobs,
          });
          if (this.initialLoading) {
            this.updateRoutingTypes();
            this.initialLoading = false;
          }
          // this.loading = false;
          // this.searchLoading = false;
        })
        .catch((error) => {
          this.$store.commit("SAVE_ERROR", { error });
          console.log("There are errors: ", error.response);
        })
        .finally(() => {
          // this.loading = false;
          this.loadingCount--;
          this.searchLoading = false;
        });
    },
    showMoreJobs() {
      this.showThisMuchJobs += 60;
    },
    filterJobSearchOption(job) {
      const jobSearchOption = this.filter.searchOption;
      // console.log(jobSearchOption);
      // console.log(job.routings.length);
      if (job && job.routings != null) {
        if (jobSearchOption == 0) {
          if (job.routings.length == 0) {
            return true;
          }
        } else if (jobSearchOption == 1) {
          if (job.routings.length < this.getRoutingTypesLength) {
            return true;
          }
        } else if (jobSearchOption == 2) {
          if (
            job.routings.length > 0 &&
            job.routings.length < this.getRoutingTypesLength
          ) {
            return true;
          }
        } else if (jobSearchOption == 3) {
          if (job.routings.length == this.getRoutingTypesLength) {
            return true;
          }
        }
        // console.log("no options found", job);
      }
      return false;
    },

    updateRoutingTypes() {
      services
        .getRoutingTypes(true)
        .then((response) => {
          this.$store.commit("SET_ROUTINGTYPES", {
            response,
          });
        })
        .catch((error) => {
          this.$store.commit("SAVE_ERROR", { error });
          console.log(error.response.data.title);
        })
        .finally(() => {
          //this.loading = false;
        });
    },
    routingTypeIsUsed(routingTypeShortName, job) {
      for (const routing of job.routings) {
        if (routing.routingType.shortName == routingTypeShortName) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
